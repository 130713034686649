<script setup>
/**
 * @module FloatingButtons
 * @description
 * This component renders 2 floating buttons at the bottom right corner of page
 * They are visible on Dashboard (Beauty Profile) page only
 */

import { NSpace, NButton, NIcon } from 'naive-ui'
import { useConfigStore } from '@/stores/config'
import { useProfileStore } from '@/stores/profile'
import { useRouter } from 'vue-router'
import { AddCircleOutline, PlayCircleOutline, RefreshCircleOutline } from '@vicons/ionicons5'
import QuizService from '@/services/quiz.service'
import AiIcon from '@/assets/icons/layout/ai-icon.svg?component'

const configStore = useConfigStore()
const profileStore = useProfileStore()
const router = useRouter()

/**
 * @function handleQuiz
 * @description This function redirects user to Quiz based on current progress
 */
function handleQuiz() {
  if (profileStore.userQuiz.progress == 100) {
    QuizService.destroyQuizId()
    QuizService.redirectQuiz()
  } else if (profileStore.userInfo.profileId) {
    QuizService.redirectQuiz(profileStore.userInfo.profileId)
  } else {
    QuizService.redirectQuiz()
  }
}

/**
 * @function handleChat
 * @description This function simply redirects user to Chat page
 */
function handleChat() {
  router.push({ name: 'Chat' })
}
</script>

<template>
  <transition name="floating-buttons">
    <n-space
      v-if="configStore.currentLayout == 'dashboard' && !profileStore.responses.getUserProfile"
      :wrap-item="false"
      :justify="configStore.screenLargerThen('sm') ? 'end' : 'center'"
      class="position-fixed left-0 left-sm-auto right-0 right-sm-6 right-xl-18 bottom-4 bottom-sm-6 bottom-xl-8 z-plus-12 w-100 w-sm-auto">

      <n-button
        round
        size="large"
        class="bg-white no-overflow clr-dark"
        :disabled="profileStore.quizLimitReached"
        @click="handleQuiz">

        <div
          class="position-absolute h-100 top-0 left-0  z-plus-1 speed-base timing-emphasized"
          :class="[
            { 'bg-secondary-light-2' : profileStore.canContinueQuiz },
            { 'bg-error-light-5' : profileStore.canRetakeQuiz || profileStore.quizLimitReached },
          ]"
          :style="{ width: profileStore.userQuiz.progress + '%' }" />

        <template #icon>
          <n-icon
            v-if="profileStore.quizUntouched"
            :component="AddCircleOutline" />
          <n-icon
            v-if="profileStore.canContinueQuiz"
            :component="PlayCircleOutline" />
          <n-icon
            v-if="profileStore.canRetakeQuiz || profileStore.quizLimitReached"
            :component="RefreshCircleOutline" />
        </template>

        <span
          v-if="profileStore.quizUntouched || profileStore.canRetakeQuiz || profileStore.quizLimitReached"
          class="position-relative z-plus-2">{{ $t('floatingButtons.analize') }}</span>
        <span
          v-if="profileStore.canContinueQuiz"
          class="position-relative z-plus-2">{{ $t('floatingButtons.continue') }}</span>
      </n-button>

      <n-button
        type="primary"
        round
        size="large"
        @click="handleChat">
        <template #icon>
          <n-icon
            :size="24"
            :component="AiIcon" />
        </template>
        {{ $t('floatingButtons.chat') }}
      </n-button>

    </n-space>
  </transition>
</template>

<style lang="scss">
@import "@/styles/@core/globals.scss";

.floating-buttons-enter-active,
.floating-buttons-leave-active {
  transition: complex-transition(
    transition(opacity, base, ease-in),
    transition(transform, shorter, emphasized),
  );
}
.floating-buttons-enter-from,
.floating-buttons-leave-to {
  opacity: 0;
  transform: translateY(150%);
}

.n-button {
  .n-button__icon {
    position: relative;
    z-index: 2;
  }
}
</style>
