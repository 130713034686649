/** essentials */
import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

/** plugins */
import { createPinia } from 'pinia'
import { resetStore } from './stores/plugins/reset-store.js'
import router from './router'
import GoogleSignInPlugin from 'vue3-google-signin'
import Vue3TouchEvents from 'vue3-touch-events'
import Markdown from 'vue3-markdown-it'

const pinia = createPinia()
pinia.use(resetStore)
app.use(pinia)
app.use(router)
app.use(GoogleSignInPlugin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
})
app.use(Vue3TouchEvents)
app.use(Markdown)

/** fonts and stylesheets */
import 'vfonts/Inter.css'
import './styles/nightvue.scss'

/** internationalization */
import i18n from './i18n.js'
app.use(i18n)

/** mount app */
app.mount('#app')
