<script setup>
import { useRoute, useRouter } from 'vue-router'
import { useConfigStore } from '@/stores/config'
import {
  NDrawer,
  NDrawerContent,
  NList,
  NListItem,
  NButton,
  NIcon,
  NSpace,
  NText,
  NA,
  NDivider,
} from 'naive-ui'
import themeOverrides from '@/theme'
import menu from '@/router/menu'
import HeaderLogo from './HeaderLogo.vue'

const route = useRoute()
const router = useRouter()
const configStore = useConfigStore()

const brandLinks = {
  terms: import.meta.env.VITE_APP_TERMS,
  policy: import.meta.env.VITE_APP_POLICY,
}

function isMenuLinkActive(item) {
  return route.name == item.route
}

function handleNavigation(item) {
  if (item.route) {
    router.push({ name: item.route })
  } else if (item.href) {
    window.location.assign(item.href)
  }
}
</script>

<template>
  <n-drawer
    v-model:show="configStore.menuOpened"
    :width="320"
    :placement="'left'">
    <n-drawer-content
      closable
      body-content-style="padding: 1rem;"
      v-touch:swipe.left="configStore.switchMenuState">

      <template #header>
        <header-logo />
      </template>

      <n-list :show-divider="false">
        <n-list-item
          v-for="(item, index) in menu"
          :key="`menu-item-${index}`"
          class="py-1">
          <n-button
            quaternary
            block
            :disabled="item.disabled"
            size="large"
            class="justify-content-start"
            :class="{ 'shadow-router-active bg-primary-opacity-3' : isMenuLinkActive(item) }"
            @click="[
              handleNavigation(item),
              configStore.switchMenuState()
            ]">
            <template #icon>
              <n-icon
                :size="18"
                :color="themeOverrides.common.primaryColor"
                :component="item.icon" />
            </template>
            <span class="clr-primary-dark-5 pl-1">{{ item.title }}</span>
          </n-button>
        </n-list-item>
      </n-list>

      <template #footer>
        <n-space
          :size="4"
          align="start"
          vertical
          class="w-100">
          <n-text depth="3">{{ new Date().getFullYear() }} © {{ $t('footer.brand') }}</n-text>
          <n-space :size="0">
            <n-a :href="brandLinks.terms">{{ $t('footer.terms') }}</n-a>
            <n-divider vertical />
            <n-a :href="brandLinks.policy">{{ $t('footer.policy') }}</n-a>
          </n-space>
        </n-space>
      </template>

    </n-drawer-content>
  </n-drawer>
</template>
