<script setup>
/**
 * @module HeaderHamburger
 * @description This component switches drawer state
 */

import { useConfigStore } from '@/stores/config'
import { NButton, NIcon } from 'naive-ui'
import { MenuSharp } from '@vicons/ionicons5'

const configStore = useConfigStore()
</script>

<template>
  <n-button
    quaternary
    round
    circle
    @click="configStore.switchMenuState">
    <template #icon>
      <n-icon
        :size="24"
        :component="MenuSharp" />
    </template>
  </n-button>

  <div
    v-if="!configStore.menuOpened"
    v-touch:swipe.right="configStore.switchMenuState"
    class="touch-element position-fixed top-0 left-0 vh-100" />
</template>

<style lang="scss" scoped>
.touch-element {
  width: 1rem;
  z-index: 400;
}
</style>
