<script setup>
import { onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useConfigStore } from '@/stores/config'
import { useChatStore } from '@/stores/chat'
import { NLayout } from 'naive-ui'

import AppProvider from '@/components/app-provider/AppProvider.vue'
import AppHeader from '@/components/app-header/Header.vue'
import ProgressBar from '@/components/progress-bar/ProgressBar.vue'
import NotificationsCenter from '@/components/notifications-center/NotificationsCenter.vue'
import FloatingButtons from '@/components/floating-buttons/FloatingButtons.vue'

const authStore = useAuthStore()
const configStore = useConfigStore()
const chatStore = useChatStore()

document.addEventListener('gesturestart', function (e) {
  if (e.scale !== 1) {
    e.preventDefault()
  }
})

onMounted(() => {
  configStore.watchWindowSizes()
  configStore.watchWindowScroll()
  configStore.setTimezone()
  configStore.saveEnvironment()
  configStore.watchDeviceType()
  configStore.restoreTheme()

  if (authStore.isAuthenticated) {
    Promise.all([authStore.verifyAuth()]).then(() => {
      chatStore.handleSocketConnect()
    })
  }

  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      authStore.verifyAuth().catch(console.error)
    }
  })
})
</script>

<template>
  <app-provider>
    <n-layout
      class="layout min-vh-100"
      :class="[`layout--${configStore.currentLayout}`]">

      <app-header v-if="configStore.showHeader" />

      <div class="container">
        <router-view />
      </div>

    </n-layout>

    <progress-bar />
    <notifications-center />
    <floating-buttons />

  </app-provider>
</template>

<style lang="scss">
@import "@/styles/@core/globals.scss";

.layout {
  &--chat {
    .layout {
      &__content {
        display: flex;
        align-items: flex-end;
        height: 100vh;
        padding-top: $header-height;
      }
    }
  }

  &--dashboard {
    padding-top: $header-height;
  }

  &--chat {
    padding-top: $header-height;

    @media #{$sm-up} {
      padding-top: $header-height + $chat-subheader-height;
    }
  }
}
</style>

<style lang="scss">
.n-icon {
  vertical-align: middle;
}

.n-list .n-list-item {
  padding: 8px 0;
}
</style>
