<script setup>
import { NButton, NIcon } from 'naive-ui'
import { BagOutline } from '@vicons/ionicons5'

const shopLink = import.meta.env.VITE_APP_MAIN_WEBSITE
</script>

<template>
  <n-button
    quaternary
    round
    tag="a"
    :href="shopLink"
    circle>
    <template #icon>
      <n-icon
        :size="24"
        depth="2"
        :component="BagOutline" />
    </template>
  </n-button>
</template>
