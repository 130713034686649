import {
  ScanSharp,
  ChatbubblesSharp,
  CartSharp,
  CalendarSharp,
  IdCardSharp,
  InformationCircleSharp,
} from '@vicons/ionicons5'
import i18n from '@/i18n.js'

const { t } = i18n.global

const menu = [
  {
    title: t('routing.dashboard'),
    icon: ScanSharp,
    route: 'Dashboard',
    href: null,
    disabled: false,
  },
  {
    title: t('routing.profile'),
    icon: IdCardSharp,
    route: 'Profile',
    href: null,
    disabled: false,
  },
  {
    title: t('routing.chat'),
    icon: ChatbubblesSharp,
    route: 'Chat',
    href: null,
    disabled: false,
  },
  {
    title: t('routing.shop'),
    icon: CartSharp,
    route: null,
    href: import.meta.env.VITE_APP_MAIN_WEBSITE,
    disabled: false,
  },
  {
    title: t('routing.calendar'),
    icon: CalendarSharp,
    route: null,
    href: null,
    disabled: true,
  },
  {
    title: t('routing.about'),
    icon: InformationCircleSharp,
    route: null,
    href: null,
    disabled: true,
  },
]

export default menu
