<script setup>
/**
 * @module HeaderUser
 * @description This component renders user dropdown in <header>
 */

import { h, ref } from 'vue'
import { NDropdown, NIcon } from 'naive-ui'
import { useAuthStore } from '@/stores/auth'
import { useChatStore } from '@/stores/chat'
import { useProfileStore } from '@/stores/profile'
import { PersonOutline, SettingsOutline, ExitOutline } from '@vicons/ionicons5'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import UserAvatar from '@/components/user-avatar/UserAvatar.vue'

const authStore = useAuthStore()
const chatStore = useChatStore()
const profileStore = useProfileStore()
const router = useRouter()
const { t } = useI18n()

const renderIcon = icon => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon)
    })
  }
}

const userMenu = ref([
  {
    label: t('userMenu.profile'),
    key: 'Profile',
    icon: renderIcon(PersonOutline),
    props: {
      onClick: () => {
        router.push({ name: 'Dashboard' })
      }
    }
  },
  {
    label: t('userMenu.settings'),
    key: 'Settings',
    icon: renderIcon(SettingsOutline),
    props: {
      onClick: () => {
        router.push({ name: 'Profile' })
      }
    }
  },
  {
    label: t('userMenu.logout'),
    key: 'Logout',
    icon: renderIcon(ExitOutline),
    route: null,
    props: {
      onClick: () => {
        Promise.all([authStore.verifyAuth()]).then(() => {
          authStore.logOut()
            .then(() => {
              router.push({ name: 'SignIn' }).then(() => {
                chatStore.$reset()
                profileStore.$reset()
              })
            })
        })
      }
    }
  },
])
</script>

<template>
  <n-dropdown
    trigger="click"
    placement="bottom-end"
    :options="userMenu">
    <user-avatar
      :size="'small'"
      :clickable="true" />
  </n-dropdown>
</template>
