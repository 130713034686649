<script setup>
import { ref } from 'vue'
import {
  NConfigProvider,
  NMessageProvider,
  NLoadingBarProvider,
  NDialogProvider,
  enUS,
  dateEnUS,
  ruRU,
  dateRuRU,
} from 'naive-ui'
import { useConfigStore } from '@/stores/config'
import themeOverrides from '@/theme.js'

const configStore = useConfigStore()

const locale = ref(import.meta.env.VITE_APP_LANG == 'en' ? enUS : ruRU || enUS)
const dateLocale = ref(import.meta.env.VITE_APP_LANG == 'en' ? dateEnUS : dateRuRU || dateEnUS)
</script>

<template>
  <n-config-provider
    :theme="configStore.currentTheme"
    :theme-overrides="themeOverrides"
    :locale="locale"
    :date-locale="dateLocale">
    <n-loading-bar-provider>
      <n-message-provider>
        <n-dialog-provider>
          <slot></slot>
        </n-dialog-provider>
      </n-message-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>
