<script setup>
/**
 * @module ProgressBar
 * @description This component watches for defined API axios requests and shows NaiveUI progressbar while request is pending
 * @see {@link https://www.naiveui.com/en-US/os-theme/components/loading-bar}
 */

import { computed, watch } from 'vue'
import { useLoadingBar } from 'naive-ui'
import { useAuthStore } from '@/stores/auth'
import { useChatStore } from '@/stores/chat'
import { useProfileStore } from '@/stores/profile'

const loadingBar = useLoadingBar()
const authStore = useAuthStore()
const chatStore = useChatStore()
const profileStore = useProfileStore()

/**
 * @constant watchableRequests [computed]
 * @type {Array}
 * @description Paste here which requests you want to show with progressbar
 */
const watchableRequests = computed(() => {
  return [
    authStore.responses.signIn,
    authStore.responses.signUp,
    authStore.responses.signUpComplete,
    authStore.responses.forgotPassword,
    authStore.responses.resetPassword,
    authStore.responses.logOut,

    chatStore.responses.getChatHistory,
    chatStore.responses.sendVoiceRecognition,

    profileStore.responses.getUserProfile,
  ]
})

watch(watchableRequests, newVal => {
  newVal.some(el => el) ? loadingBar.start() : loadingBar.finish()
})
</script>

<template>
  <div></div>
</template>
